import md5 from 'crypto-js/md5.js'

const LIBRAVATAR_SERVER = 'https://libravatar.stendahls.dev';

const DEFAULT_IMAGES = [
    'https://cdn.stendahls.live/stendahls/avatar-placeholders/placeholder-1.jpg',
    'https://cdn.stendahls.live/stendahls/avatar-placeholders/placeholder-2.jpg',
    'https://cdn.stendahls.live/stendahls/avatar-placeholders/placeholder-3.jpg',
    'https://cdn.stendahls.live/stendahls/avatar-placeholders/placeholder-4.jpg',
    'https://cdn.stendahls.live/stendahls/avatar-placeholders/placeholder-5.jpg',
    'https://cdn.stendahls.live/stendahls/avatar-placeholders/placeholder-6.jpg',
];

const getRandomDefaultImageURL = function getRandomDefaultImageURL(){
    return DEFAULT_IMAGES[ Math.floor( Math.random() * DEFAULT_IMAGES.length ) ];
};

const libravatarImage = (email, size = 512) => {
    return `${ LIBRAVATAR_SERVER }/avatar/${ md5( email ) }?s=${size}&default=${ encodeURIComponent( getRandomDefaultImageURL() ) }&w=512&h=512&t=square&a=center`;
};

export default libravatarImage;

// import TimeAgo from 'react-timeago';
import ImageMapper from 'react-img-mapper';
import { InView } from "react-intersection-observer";

import formatName from '../modules/format-name';
import libravatarImage from '../modules/libravatar-image.mjs';

import areav1 from '../area-v1.json';
import areav2 from '../area-v2.json';
import areav3 from '../area-v3.json';
import areav4 from '../area-v4.json';
import areav5 from '../area-v5.json';
import areav6 from '../area-v6.json';

const areas = {
    '1': areav1,
    '2': areav2,
    '3': areav3,
    '4': areav4,
    '5': areav5,
    '6': areav6,
};

const identityReplacements = {
    'madeleine.moller': 'madeleine.moller-stahl',
};

// Function to determine if a point is inside the polygon
// function isPointInPolygon(x, y, coords) {
//     let inside = false;
//     for (let i = 0, j = coords.length / 2 - 1; i < coords.length / 2; j = i++) {
//         const xi = coords[i * 2], yi = coords[i * 2 + 1];
//         const xj = coords[j * 2], yj = coords[j * 2 + 1];
//         const intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
//         if (intersect) inside = !inside;
//     }
//     return inside;
// }

// Function to get a random point within the bounding box of the polygon
function getRandomPointInPolygon(coords) {
    const minX = Math.min(...coords.filter((_, index) => index % 2 === 0));
    const maxX = Math.max(...coords.filter((_, index) => index % 2 === 0));
    const minY = Math.min(...coords.filter((_, index) => index % 2 === 1));
    const maxY = Math.max(...coords.filter((_, index) => index % 2 === 1));

    let attempts = 0;
    const maxAttempts = 1000; // To avoid infinite loops

    while (attempts < maxAttempts) {
        attempts++;
        const x = Math.random() * (maxX - minX) + minX;
        const y = Math.random() * (maxY - minY) + minY;

        return {
            x,
            y,
        };
        // if (isPointInPolygon(x, y, coords)) {
        //     return { x, y };
        // }
    }

    console.error('Failed to find a valid point within the polygon');

    // Show them in the stairs
    return {
        x: 652,
        y: 329,
    };
}

const MarauderMap = function({validDevices, urlParams}){
    const avatarSize = 40;

    const handleClick = (area) => {
        console.log(area);
    };

    let maps = [];

    for(let i = 1; i <= 6; i = i + 1){
        const URL = `${process.env.PUBLIC_URL}/images/map-v${i}.png`;
        const MAP = {
            name: `map-v${i}`,
            areas: areas[i],
        };

        maps.push(
            <InView
                key = {`map-v${i}`}
                threshold = {0.5}
            >
                {({ inView, ref, entry }) => (
                    <div
                        className= {`map-wrapper ${inView ? 'in-view' : ''}`}
                        ref={ref}
                    >
                        <ImageMapper
                            src={URL}
                            map={MAP}
                            onClick = {handleClick}
                        />
                        {/* {`Is it in view? : ${inView}`} */}
                        {validDevices.map( ( device ) => {
                            if ( device.identity in identityReplacements ) {
                                device.identity = identityReplacements[ device.identity ];
                            }

                            // console.log(device);

                            if(device.ap.location[0] !== i.toString()){
                                return true;
                            }

                            let deviceArea = areas[i].find( area => area.rooms.includes(device.ap.location ));

                            if(!deviceArea){
                                console.log(`No area found for ${device.ap.location}`);

                                deviceArea = areav1[0];

                                return true;
                            }

                            const position = getRandomPointInPolygon(deviceArea.coords);

                            return ( <div
                                className = { 'device-wrapper' }
                                title = { device.identity }
                                key = { device.mac }
                                style = {{
                                    top: `${ position.y - avatarSize / 2 }px`,
                                    left: `${ position.x - avatarSize / 2 }px`,
                                    width: avatarSize,
                                    height: avatarSize,
                                }}
                            >
                                <div
                                    className='person-name'
                                >
                                    { formatName( device.identity ) }
                                </div>
                                <div
                                    className = { 'last-seen-wrapper' }
                                >
                                    <img
                                        alt = { device.identity }
                                        src = { libravatarImage(`${ device.identity }@stendahls.se`) }
                                    />
                                    {/* { urlParams.get( 'debug' ) &&
                                        <TimeAgo
                                            date = { device.lastSeen * 1000 }
                                        />
                                    } */}
                                </div>
                            </div> );
                        } )}
                    </div>
                )}
            </InView>
        );
    };

    return (
        <div
            className='marauder-map'
        >
            { maps }
        </div>
    );
}

export default MarauderMap;
